import * as React from "react";
import { Helmet } from "react-helmet";
import Header from "components/uikit/Header/Header.js";
import Footer from "components/uikit/Footer/Footer.js";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Amplify from "aws-amplify";
import awsconfig from "aws-exports";
// import "assets/css/normalize.css";

Amplify.configure({
  ...awsconfig,
  aws_appsync_authenticationType: "API_KEY",
});
type Props = {
  children: React.ReactNode;
};

export default function Common(props: Props) {
  const { children } = props;
  React.useEffect(() => {
    Amplify.configure({
      ...awsconfig,
      aws_appsync_authenticationType: "API_KEY",
    });
    console.log("aws_appsync_authenticationType: API_KEY");
  }, []);

  return (
    <>
      <Header
        color="transparent"
        brand="ONLINE BOAT SHOW"
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />
      <div>{children}</div>
      <Footer />
    </>
  );
}
